import { AlertCircle, CheckCircle } from 'lucide-react'
import { classNames } from '~/utils/class-names'

import type { AlertType } from '~/utils/message'

export type AlertProps = {
  type: AlertType
  children?: React.ReactNode | React.ReactElement
  onDismiss?(value: boolean): () => void
  title?: string
  className?: string
}

const AlertIcon = ({ type }: { type: string }) => (
  <AlertCircle
    aria-hidden="true"
    className={classNames('h-5 w-5', {
      'text-red-400': type === 'danger',
      'text-brand-400': type === 'info',
      'text-green-400': type === 'success',
      'text-yellow-400': type === 'warn',
    })}
  />
)
const SuccessIcon = () => <CheckCircle aria-hidden="true" className="h-5 w-5 text-green-400" />

export const Alert = ({ type, children, title, className }: AlertProps) => {
  const textColor = classNames({
    'text-red-800': type === 'danger',
    'text-brand-800': type === 'info',
    'text-yellow-800': type === 'warn',
    'text-green-800': type === 'success',
  })
  const bgColor = classNames({
    'bg-red-50': type === 'danger',
    'bg-brand-50': type === 'info',
    'bg-yellow-50': type === 'warn',
    'bg-green-50': type === 'success',
  })
  return (
    <div className={classNames('my-2 flex rounded-md p-4', bgColor, className)}>
      <div className="flex-shrink-0">
        {['info', 'warn', 'danger'].includes(type) ? <AlertIcon type={type} /> : <SuccessIcon />}
      </div>
      <div className="ml-3 flex flex-col gap-2">
        {title && <h3 className={classNames('text-sm font-medium', textColor)}>{title}</h3>}
        {children && <div className={classNames('text-sm', textColor)}>{children}</div>}
      </div>
    </div>
  )
}
